<template>
  <v-alert v-model="show" type="warning">
    {{ $t('error.no_configuration') }}
    <span class="clickable" @click="$router.push({ name: routes.settings.name })">
    <v-icon class="body-1 white--text color-white">mdi-exit-to-app</v-icon>
    <span>{{ $t('sections.settings') }}</span></span>
  </v-alert>
</template>

<script>
import routes from '@/router/routes'
export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      routes
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-icon {
    color:white !important;
  }
}
</style>
